.App {
  text-align: center;
}
@font-face {
  font-family: arena;
  src: url(./Arenq.otf);
}
body {
  background-image: url(./bkg.jpg);
  background-size: 100vw 100vh;
}
.mintContainer {
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important; */
  width: 70vw;
}
.MuiButton-label {
  color: white !important;
  font-size: 2vw !important;
  padding: 0.6vw;
}
.topLogo {
  position: absolute;
  left: 2vw;
  top: 2vh;
  width: 8vw;
  height: auto;
}
.nft__img {
  width: 12vw;
  height: auto;
}
.iamges {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}
.nft__gif {
  margin-top: 4vh;
  width: 35vw;
  height: auto;
}
.MuiButton-label {
  /* width: 6.7vw !important; */
  font-family: arena !important;
  text-align: center !important;
  padding-left: 1vw !important;
  padding-right: 1vw !important;
  font-size: 1.5vw !important;
}
.MuiButton-root {
}
@media screen and (max-width: 1024px) {
  .nft__img {
    width: 20vw;
    height: auto;
  }
  .nft__gif {
    margin-top: 4vh;
    width: 60vw;
    height: auto;
  }
  .MuiButton-label {
    color: white !important;
    font-size: 4vw !important;
    padding: 0.8vw;
  }
}
